// extracted by mini-css-extract-plugin
export var billetShadow = "mobile-development-module--billet-shadow--78448";
export var colorScheme__background__grayPale = "mobile-development-module--colorScheme__background__gray-pale--bb3eb";
export var common__list__techLogosRounded = "mobile-development-module--common__list__techLogos-rounded--aacc7";
export var dash = "mobile-development-module--dash--0f1ce";
export var icomoon = "mobile-development-module--icomoon--d26bc";
export var largeWiteText = "mobile-development-module--largeWiteText--da22d";
export var leadingBusiness = "mobile-development-module--leadingBusiness--aca44";
export var leadingBusiness__capture = "mobile-development-module--leadingBusiness__capture--15b88";
export var leadingBusiness__summary = "mobile-development-module--leadingBusiness__summary--990c8";
export var leadingBusiness__tech = "mobile-development-module--leadingBusiness__tech--32fa7";
export var leadingBusiness__techLogos = "mobile-development-module--leadingBusiness__techLogos--c14b0";
export var lowercase = "mobile-development-module--lowercase--47220";
export var mobileDevelopmentBlock = "mobile-development-module--mobileDevelopmentBlock--8091c";
export var mobileDevelopmentBlock__headline = "mobile-development-module--mobileDevelopmentBlock__headline--6a1f4";
export var mobileDevelopmentBlock_headingCarousel = "mobile-development-module--mobileDevelopmentBlock_headingCarousel--782a8";
export var partnership = "mobile-development-module--partnership--b78f4";
export var services = "mobile-development-module--services--32275";
export var servicesH2 = "mobile-development-module--servicesH2--045f5";
export var technologyPartner = "mobile-development-module--technologyPartner--85553";
export var technologyPartner__carousel = "mobile-development-module--technologyPartner__carousel--422b7";
export var transition = "mobile-development-module--transition--0a085";
export var transitionBackground = "mobile-development-module--transition-background--6cab1";
export var transitionReverse = "mobile-development-module--transition-reverse--feafe";
export var trusted = "mobile-development-module--trusted--aff7c";
export var trustedH2 = "mobile-development-module--trustedH2--4f514";
export var uppercase = "mobile-development-module--uppercase--49527";