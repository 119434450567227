import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import { Link } from "gatsby"

import { IMG } from "../../const"
import { CAROUSEL_TEXT } from "../mobile-development/const"
import "./styles.scss"
import NotButton from "./NotButton"

const TechnologyPartnerCarousel = ({ screenWidth }) => {
  const [slider1, setSlider1] = useState(null)
  const [slider2, setSlider2] = useState(null)

  useEffect(() => {
    document.addEventListener("keydown", handleKeyClicking)
    return () => {
      document.removeEventListener("keydown", handleKeyClicking)
    }
  }, []) // eslint-disable-line

  const handleKeyClicking = e => {
    if (e.keyCode === 39) {
      slider1.slickNext()
    }
    if (e.keyCode === 37) {
      slider1.slickPrev()
    }
  }

  const titles = () => (
    <div className="titles-wrapper">
      <Slider
        asNavFor={slider1}
        ref={slider => setSlider2(slider)}
        slidesToShow={1}
        swipeToSlide={true}
        focusOnSelect={true}
        nextArrow={<NotButton />}
        prevArrow={<NotButton />}
        className={"titles-carousel"}
        infinite={false}
      >
        {CAROUSEL_TEXT.map(item => (
          <div key={item.id}>
            <h5>{item.title}</h5>
          </div>
        ))}
      </Slider>
    </div>
  )

  return (
    <div className="mobile-dev-carousel-container">
      {screenWidth >= 640 && titles()}
      <div className="cards-wrapper custom-buttons">
        <Slider
          asNavFor={slider2}
          ref={slider => setSlider1(slider)}
          slidesToShow={1}
          slidesToScroll={1}
          className={"cards-carousel"}
          fade={true}
          infinite={false}
        >
          {CAROUSEL_TEXT.map(item => (
            <div key={item.id} className="carousel-item">
              <div className="item-image-wrapper">
                <picture>
                  <source
                    srcSet={`${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_464.avif,	${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_928.avif 2x,	${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_1392.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    srcSet={`${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_464.webp,	${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_928.webp 2x,	${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_1392.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    srcSet={`${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_464.png,	${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_928.png 2x,	${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_1392.png 3x`}
                  />
                  <img
                    src={`${IMG.MOBILE_DEVELOPMENT}/carousel/${item.image}_928.png`}
                    alt={item.title}
                    width="464"
                    height="400"
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="carousel-item-text">
                <h5>{item.title}</h5>
                <p>{item.text}</p>
                {item.link && (
                  <Link to={item.link} className="goldFatLink">
                    More details
                  </Link>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {screenWidth < 640 && titles()}
    </div>
  )
}

export default TechnologyPartnerCarousel
