import React from "react"

import { TITLES } from "./const"
import * as styles from "./titles.module.scss"

const Titles = () => {
  return (
    <div className={styles.titleWrapper}>
      {TITLES.map(title => (
        <div className={styles.title} key={title.id}>
          <h2 className="common__textOutline">{title.text}</h2>
        </div>
      ))}
    </div>
  )
}

export default Titles
