import React from "react"

export const MOBILE_DEVELOPMENT_SERVICES = [
  {
    id: 1,
    title: "Android Development",
    text:
      "We help businesses establish their presence on any modern device and platform using the latest Android application development technologies.",
  },
  {
    id: 2,
    title: "iOS Development",
    text: "Empower mobile apps with Apple technology users.",
  },
  {
    id: 3,
    title: "Multi-Platform App Development",
    text:
      "Get top-notch mobile applications that run on multiple platforms while providing your users with an app that fits their OS. Use this alternative to reduce costs and time-to-market and reach more users without losing quality.",
  },
  {
    id: 4,
    title: "Progressive Web App Development",
    text:
      "Build progressive web apps that combine the best technical solutions applied in mobile and web applications, beneficial in terms of ease of development and distribution, and also advantages for your business.",
  },
]

export const LOGO_TRUSTED_BY = [
  {
    id: 1,
    name: "Calvin Klein",
    width: "176",
    height: "24",
    logo: "logo-calvin-klein",
  },
  {
    id: 2,
    name: "s.Oliver",
    width: "110",
    height: "40",
    logo: "logo-s-oliver",
  },
  {
    id: 3,
    name: "Hugo Boss",
    width: "107",
    height: "40",
    logo: "logo-hugo-boss",
  },
]

export const TECHNOLOGIES = [
  { name: "Jetpack", icon: "jetpack.svg" },
  { name: "Ionic", icon: "ionic.svg" },
  { name: "Kotlin", icon: "kotlin.svg" },
  { name: "Swift", icon: "swift.svg" },
  { name: "Flutter", icon: "flutter.svg" },
]

export const CAROUSEL_TEXT = [
  {
    id: 1,
    title: "Full circle software development",
    text:
      "We can plan, design, and develop your product as a solo vendor. We take care of all aspects of software development, including product design and architecture, UI/UX design, Web App development, Quality Assurance, project management, and project support.",
    image: "imgs-4",
    link: "/software-development/",
  },
  {
    id: 2,
    title: "World-class Product Design expertise",
    text:
      "Wondering if you should create a new design or buy a template? Many of our customers have this dilemma when they decide to build a webpage. This option is excellent for testing your ideas.",
    image: "imgs",
    link: "/product-design/",
  },
  {
    id: 3,
    title: "Cloud Infrastructure deployment & support",
    text:
      "We can create or develop cloud infrastructure for business. Want to remove your business from the cloud? We'll take care of it. Want to optimize your finances in DevOps? We will find the cheapest solution without losing quality.",
    image: "imgs-1",
    link: "/project-support/",
  },
  {
    id: 4,
    title: "Expertise in data analytics and custom dashboard development",
    text:
      "We have extensive knowledge in developing high-load dashboards with modern infographics. Our teammates worked in world-famous companies like HP, Pearson, Adidas, Boss, Calvin Klein, and s’Oliver.",
    image: "imgs-2",
    link: "/dashboard/",
  },
  {
    id: 5,
    title: "Mobile development ",
    text:
      "Android or iOS app development? You don't have to choose. We make solutions for all operating systems.",
    image: "imgs-3",
    link: "",
  },
]

export const TITLES = [
  {
    id: 1,
    text: (
      <>
        Improve <strong>your</strong> business with{" "}
        <strong>a&nbsp;mobile app</strong>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        Put your product in <strong>the hand of your</strong> customers sooner
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        We create <strong>beautiful</strong> mobile apps <strong>with</strong>{" "}
        modern <strong>updates</strong>
      </>
    ),
  },
]
