// extracted by mini-css-extract-plugin
export var billetShadow = "titles-module--billet-shadow--d2350";
export var colorScheme__background__grayPale = "titles-module--colorScheme__background__gray-pale--4bdf8";
export var icomoon = "titles-module--icomoon--ac5ab";
export var lowercase = "titles-module--lowercase--61cc3";
export var shiftRight1 = "titles-module--shiftRight1--71158";
export var shiftRight2 = "titles-module--shiftRight2--c08ca";
export var shiftRight3 = "titles-module--shiftRight3--22881";
export var title = "titles-module--title--33408";
export var titleWrapper = "titles-module--titleWrapper--c7974";
export var transition = "titles-module--transition--1abaf";
export var transitionBackground = "titles-module--transition-background--ddb3d";
export var transitionReverse = "titles-module--transition-reverse--9f65a";
export var uppercase = "titles-module--uppercase--b4612";